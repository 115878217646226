.landingpage {}

.top {
    width: 100%;
    position: relative;
    background-color: #DEF4C8;
    height: 492px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

}

.details {
    max-width: 688px;
    height: 254px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    flex-direction: column;
    margin-top: 80px;
}

.join {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.black {
    color: #0C2D1A;
    text-align: center;
    font-family: Plus Jakarta Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 106%;
    /* 33.92px */
}

.gold {
    color: #B88D00;
    font-family: Plus Jakarta Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 106%;
}

.para {
    display: flex;
    max-width: 572px;
    width: 100%;
    height: 67px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.access {
    color: #FFF;
    text-align: center;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 8px;
    background-color: #0C2D1A;
    display: flex;
    width: 149px;
    height: 48px;
    padding: 6.76px 32.11px;
    justify-content: center;
    align-items: center;
    gap: 2.817px;
    cursor: pointer;
    z-index: 2;
}
.access:hover{
    background-color: #0D4224;
    cursor: pointer;
}

.images {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
}

.middle {
    min-height: 358px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 3px solid #3F3F3F;
    border-top: 3px solid #3F3F3F;
}

.benifit {
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.beni {
    color: #0C2D1A;
    font-family: Plus Jakarta Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 106%;
    /* 50.88px */
    display: flex;
    max-width: 390px;
    height: 120px;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    gap: 24px;
    text-align: left;
}

.tick {
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: baseline;


}

.tick div {
    color: #000;
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    max-width: 406px;
    justify-content: center;
    gap: 11px;
    text-align: left;
}

.tick>div>img {
    width: 24px;
    height: 24px;
}
.tick>div>span {
    color: #B88D00;
    margin-top: 0;
    margin-bottom: 0;

}

.middle-img {
    position: absolute;
    bottom: 0;
    left: 0;
}

.bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.bottom h2 {
    color: #0C2D1A;
    font-family: Plus Jakarta Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 106%;
    /* 50.88px */
    width: 100%;
}

.bottom-img {
    position: absolute;
    left: 0;
    bottom: 0;
}

.form-container {
    max-width: 1120px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.form {
    max-width: 802px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    z-index: 1;
}

.name-email {
    display: flex;
    gap: 32px;
    width: 100%;
    justify-content: flex-end;
}

.name-email input {
    flex-shrink: 0;
    color: #3F3F3F;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 169.231% */
    border: none;
    border-radius: 4px;
}

.name,
.email,
.number,
.type,
.media {
    color: #3F3F3F;
    font-family: Cabin;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 169.231% */
    border-radius: 4px;
    border: 1px solid #000;
    padding-left: 10.5px;
    padding-right: 10.5px;
    padding-top: 5px;
    padding-bottom: 5px;
    gap: 4px;
    width: 95%;
    height: 20.6px !important;
}

.name {
    display: flex;
    align-items: center;
}

.email {
    display: flex;
    align-items: center;
}

.number {
    max-width: 244px;
    width: 95%;
    display: flex;
    align-items: center;

}

.form input,
.number input {
    color: #242424;
    font-family: Cabin;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 169.231% */
    border: none;
    width: 50%;
    flex-grow: 1;

}

.type {
    max-width: 526px;
    width: 100%;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.number-type {
    display: flex;
    gap: 32px;
    width: 100%;
    justify-content: flex-end;
}

.media {
    width: 97%;
    display: flex;
    align-items: center;
}

.desc {
    width: 100%;
}

.desc textarea {
    border-radius: 6px;
    border: 1px solid #242424;
    background-color: #FEFEFE;
    display: flex;
    max-width: 783px;
    width: 100%;
    height: 101px;
    padding: 8px 17px;
    align-items: flex-start;
    gap: 8px;
    resize: none;
    padding-right: 0px;
    font-family: Cabin;

}

.submit {
    color: #FFF;
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 32px;
    background-color: #0C2D1A;
    display: inline-flex;
    height: 32px;
    padding: 0px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    margin-top: 32px;
    margin-bottom: 163px;
}
.submit:hover{
    cursor: pointer;
}

.logo {
    width: 150px;
    height: 60.974px;
    flex-shrink: 0;
    display: flex;
    gap: 7.8px;
    position: absolute;
    right: 85px;
    bottom: 26px;
}

.brich {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.brich h3 {
    color: #2C323F;
    font-family: Playfair Display;
    font-size: 24.247px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 0px;
}

.brich p {
    color: #2C323F;
    font-family: Inter;
    font-size: 10.103px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 0px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.footer {
    height: 48px;
    flex-shrink: 0;
    background-color: #2C323F;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.left {
    width: 338px;
}

.right {
    width: 332px;
}

.social {
    display: flex;
    width: 90%;
    gap: 24px;
    align-items: baseline;
}
a{
    text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.popup {
    display: flex;
    justify-content: center;
    align-items: baseline;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  
  .popup-content {
    max-width: 823px;
    min-height: 448px;
    flex-shrink: 0;
    border: 8px solid var(--Container, #0C2D1A);
background: var(--White, #FEFEFE);
    text-align: center;
    position: relative;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    top: 117px;

  }
  .content2{
    gap: 60px;
  }
  .cross{
    display: flex;
    width: 94%;
    justify-content: flex-end;
    margin-top: 16px;
cursor: pointer;
  }
  .line{
background-color: #959595;
width: 1px;
height: 17px;
flex-shrink: 0;
  }
  .error-feild{
    width: 100%;
  }
  .error-feild1{
    width: 244px;
  }
  .error-feild,
  .error-feild1{
    display: flex;
    flex-direction: column;
    align-items: first baseline;
    
  }
  .error{
    color: #FF4242;
font-family: Cabin;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 19.2px */
letter-spacing: 0.454px;
  }

@media (max-width:1250px) {

    .left,
    .right {
        width: 179.448px;
        height: 194.844px;
        flex-shrink: 0;
    }

    .bottom-img {
        width: 131.978px;
        height: 146.942px;
        flex-shrink: 0;
        bottom: -13px;
    }

    .form-container {
        align-items: center;
    }
    .images{
        bottom: -7px;
    }
    .tick div{
        height: auto;
    }
}

@media (max-width:900px) {
    .bottom h2 {
        display: flex;
    }

    .number {
        max-width: 95%;
    }

    .type {
        max-width: 45%;
    }

    .beni {
        display: flex;
        max-width: 195px;
        height: 120px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: #0C2D1A;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 106%;
        /* 33.92px */
    }
    .error-feild1 {
        width: 100%;
    }
}

@media (max-width:850px) {

    .black,
    .gold {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 106%;
        /* 25.44px */
    }

    .para {
        color: #000;
        text-align: center;
        font-family: Cabin;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 90%;
    }

    .details {
        gap: 10px;
    }
}

@media (max-width:700px) {
    .form-container h2{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .number-type,
    .name-email{
        flex-direction: column;
    }
    .desc textarea,
    .name,
    .email,
    .type,
    .number{
        max-width: 97%;
        width: 100%;
    }
    .access{
        display: flex;
width: 149px;
height: 32px;
padding: 6.76px 32.11px;
justify-content: center;
align-items: center;
gap: 2.817px;
flex-shrink: 0;
    }
    .para{
        height: auto;
    }
}
@media (max-width:650px) {
    .benifit {
        flex-direction: column;
        margin-bottom: 53px;
        margin-top: 53px;
        gap: 32px;
    }

    .middle-img {
        display: none;
    }
    .form input{
        width: 50%;
    }
    .logo{
        right: 30px;
    }
}

@media (max-width:400px) {
    .beni {
        color: #0C2D1A;
        font-family: Plus Jakarta Sans;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 106%;
        /* 50.88px */
        display: flex;
        width: 252px;
        height: 142px;
        flex-direction: column;
        justify-content: center;
    }
    .black, .gold {
        font-size: 20px;

    }
    .name, .email, .number, .type, .media {
        padding-right: 0px;
    }
    .left{
        width: 142.448px;
        height: 157.844px;
    }
    .right{
        width: 131.052px;
        height: 161.621px;
    }

}